import { Link } from "react-router-dom";
import Header from "../components/Header";
import PageWrapper from "../components/PageWrapper";

export default function Home() {
  return (
    <PageWrapper paddingAmount={6}>
      <Header />
      <main>
        <div className="pt-6 bg-gray-900 sm:pt-12 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div>
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0">
                <div>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl xl:text-6xl">
                    <span>2B</span>{" "}
                    <span className="text-blue-600">Fiber Optic</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    With over 12 years in the telecommunications industry, 2B
                    Fiber Optic is your trusted partner for fiber optic
                    splicing, testing, and certification on new and existing
                    networks.
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div className="sm:flex sm:items-center sm:justify-between lg:w-full lg:justify-center">
                        <div className="min-w-0 flex-1">
                          <Link
                            to="/about"
                            className="block w-full max-w-xs px-4 py-3 text-left bg-gray-200 rounded-md border-0 text-base text-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                          >
                            Learn More
                          </Link>
                        </div>
                        <div className="min-w-0 flex-1">
                          <Link
                            to="/contact"
                            className="block w-full max-w-xs mt-4 sm:mt-0 sm:mx-4 px-4 py-3 text-left bg-blue-600 rounded-md border-0 text-base text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                          >
                            Contact 2B
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </PageWrapper>
  );
}
