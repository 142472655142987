import { Link } from "react-router-dom";
import HeaderLogo from "../media/2B_logo_small.png";

export default function Header() {
  return (
    <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6">
      <div className="flex items-center flex-1">
        <div className="flex items-center justify-between w-full md:w-auto">
          <Link to="/">
            <img
              className="h-16 w-auto sm:h-20"
              src={HeaderLogo}
              alt="2B Fiber Optic Logo"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}
